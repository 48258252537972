import React from 'react'
import nodelogo from '../assets/images/node_logo.svg';

const HeaderNode = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={nodelogo} alt="Python vs Java" /></span>
        <h1>Python vs Node.js</h1>
        <h2>Comparison of Python and Node.js</h2>
    </header>
)

export default HeaderNode
